import clsx from 'clsx'
import LoadingContainer from './Container'

export default function Loading() {
  return (
    <div
      className={clsx('flex', 'justify-center', 'items-center', 'text-[20px]')}
    >
      <div
        className={clsx(
          'font-DespairTime',
          'text-center',
          'flex',
          'flex-col',
          'items-center',
          'space-y-[100px]',
        )}
      >
        <div className="text-pink">LOADING</div>
        <div className={clsx('relative', 'h-[1px]', 'w-[200px]', 'bg-white')}>
          <div
            className={clsx(
              'absolute',
              'w-[16px]',
              'h-[16px]',
              'rounded-full',
              'bg-pink',
              'translate-x-[-50%]',
              'translate-y-[-50%]',
              'animate-loading',
            )}
          />
        </div>
        <div className="flex justify-center flex-col items-center">
          <div className="font-bold text-3xl">로딩 중입니다.</div>
          잠시만 기다려주세요
        </div>
      </div>
    </div>
  )
}

Loading.Container = LoadingContainer
