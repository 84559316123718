import clsx from 'clsx'
import CheckBoxOffSvg from '@/assets/icons/checkbox_off.svg'
import { SvgProps } from '@/types/svg'

const CheckBoxOffIcon = ({
  alt = '',
  className = '',
  width = 50,
  height = 50,
  ...rest
}: SvgProps) => {
  return (
    <i role="presentation" aria-label={alt} className="w-fit">
      <CheckBoxOffSvg
        width={width}
        height={height}
        className={clsx(className)}
        {...rest}
      />
    </i>
  )
}

export default CheckBoxOffIcon
