export const PATH = {
  api: {},
  home: '/',
  login: '/login',
  invitations: '/invitations',
  enrollments: '/enrollments',
  participate: '/participate',
  posts: '/posts',
  signUp: '/signup',
  signUpComplete: '/signup/complete',
}

export const S3Url = process.env.NEXT_PUBLIC_S3_URL
