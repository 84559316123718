import clsx from 'clsx'
import OnobOffSvg from '@/assets/icons/onob_off.svg'
import { SvgProps } from '@/types/svg'
import { colors } from '@/styles/theme'

const OnobOffIcon = ({
  alt = '',
  className = '',
  width = 50,
  height = 50,
  fill = colors.white,
  ...rest
}: SvgProps) => {
  return (
    <i role="presentation" aria-label={alt}>
      <OnobOffSvg
        width={width}
        height={height}
        className={clsx(className)}
        fill={fill}
        {...rest}
      />
    </i>
  )
}

export default OnobOffIcon
