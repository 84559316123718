import clsx from 'clsx'
import WonSvg from '@/assets/icons/won.svg'
import { SvgProps } from '@/types/svg'
import { colors } from '@/styles/theme'

const WonIcon = ({
  alt = '',
  className = '',
  width = 26,
  height = 26,
  fill = colors.black,
  ...rest
}: SvgProps) => {
  return (
    <i role="presentation" aria-label={alt}>
      <WonSvg
        width={width}
        height={height}
        className={clsx(className)}
        fill={fill}
        {...rest}
      />
    </i>
  )
}

export default WonIcon
