import clsx from 'clsx'
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import type { Color, ColorConfig, Size, SizeConfig } from './types'

const SIZE_CONFIG: SizeConfig = {
  sm: 'w-fit',
  md: 'w-full p-[20px]',
  lg: 'w-full',
}

const COLOR_CONFIG: ColorConfig = {
  pink: 'bg-pink text-black disabled:bg-gray700 disabled:text-black',
  black: 'bg-black text-pink disabled:bg-pink2 disabled:text-black',
}

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  size?: Size
  color?: Color
  disabled?: boolean
  outlined?: boolean
}

const Button = ({
  children,
  size = 'md',
  color = 'pink',
  outlined = false,
  className,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  return (
    <button
      {...props}
      className={clsx(
        'flex',
        'items-center',
        'justify-center',
        'text-3xl',
        'font-DespairTime',
        SIZE_CONFIG[size],
        COLOR_CONFIG[color],
        className,
      )}
    >
      {children}
    </button>
  )
}

export default Button
