import clsx from 'clsx'
import CloseSvg from '@/assets/icons/close.svg'
import { SvgProps } from '@/types/svg'
import { colors } from '@/styles/theme'

const CloseIcon = ({
  alt = '',
  className = '',
  width = 50,
  height = 50,
  fill = colors.white,
  ...rest
}: SvgProps) => {
  return (
    <i role="presentation" aria-label={alt}>
      <CloseSvg
        width={width}
        height={height}
        className={clsx(className)}
        fill={fill}
        {...rest}
      />
    </i>
  )
}

export default CloseIcon
