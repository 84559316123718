import clsx from 'clsx'
import NextSvg from '@/assets/icons/next.svg'
import { SvgProps } from '@/types/svg'
import { colors } from '@/styles/theme'

const NextIcon = ({
  alt = '',
  className = '',
  width = 50,
  height = 50,
  fill = colors.white,
  ...rest
}: SvgProps) => {
  return (
    <i role="presentation" aria-label={alt}>
      <NextSvg
        width={width}
        height={height}
        className={clsx(className)}
        fill={fill}
        {...rest}
      />
    </i>
  )
}

export default NextIcon
