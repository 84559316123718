import clsx from 'clsx'
import CircleSvg from '@/assets/icons/circle.svg'
import { SvgProps } from '@/types/svg'

const CircleIcon = ({
  alt = '',
  className = '',
  width = 50,
  height = 50,
  fill = 'black',
  ...rest
}: SvgProps) => {
  return (
    <i role="presentation" aria-label={alt}>
      <CircleSvg
        width={width}
        height={height}
        className={clsx(className)}
        fill={fill}
        {...rest}
      />
    </i>
  )
}

export default CircleIcon
