import clsx from 'clsx'
import { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { ArrowLeftIcon, CloseIcon } from '@/components/Icons'

export const buttonOptions = ['none', 'close', 'back'] as const
type ButtonType = (typeof buttonOptions)[number]

export interface HeaderProps {
  title: ReactNode
  leftButton?: ButtonType | ReactNode
  rightButton?: ButtonType | ReactNode
  leftButtonProps?: Omit<ComponentProps<'button'>, 'type'>
  rightButtonProps?: Omit<ComponentProps<'button'>, 'type'>
  bottomBorder?: boolean
}

const Header = ({
  title,
  leftButton,
  rightButton,
  leftButtonProps,
  rightButtonProps,
  bottomBorder = true,
}: HeaderProps) => {
  return (
    <header
      className={clsx(
        'inner',
        'fixed',
        'flex',
        'top-0',
        'left-0',
        'right-0',
        'items-center',
        'justify-between',
        'z-10',
        'w-full',
        'h-[72px]',
        'bg-black',
        bottomBorder && ['border-b-white', 'border-b-[1px]'],
      )}
    >
      {!!leftButton && (
        <HeaderButton
          type={leftButton}
          {...leftButtonProps}
          className={clsx('ml-2.5', leftButtonProps?.className)}
        />
      )}
      <span
        className={clsx(
          'font-DespairTime',
          'px-4',
          'text-3xl',
          'leading-[120%]',
          'tracking-[2.2px]',
          !!leftButton ? 'text-end' : 'text-start',
        )}
      >
        {title}
      </span>
      {!!rightButton && (
        <HeaderButton
          type={rightButton}
          {...rightButtonProps}
          className={clsx('mr-2.5', rightButtonProps?.className)}
        />
      )}
    </header>
  )
}

const ButtonIcon = ({ type }: { type: ButtonType }) => {
  if (type === 'close') return <CloseIcon />
  if (type === 'back') return <ArrowLeftIcon />
  else return null
}

interface HeaderButtonProps extends Omit<ComponentProps<'button'>, 'type'> {
  type: ButtonType | ReactNode
}
const HeaderButton = ({
  type = 'none',
  className,
  ...props
}: PropsWithChildren<HeaderButtonProps>) => {
  return (
    <button
      type="button"
      className={clsx('min-w-[24px]', className)}
      {...props}
    >
      {typeof type === 'string' && isButtonType(type) ? (
        <ButtonIcon type={type} />
      ) : (
        <>{type}</>
      )}
    </button>
  )
}

const isButtonType = (type: string): type is ButtonType => {
  return !!buttonOptions.find((option) => option === type)
}

export default Header
