import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import Loading from '@/components/Loading'
import { createPortal } from 'react-dom'

const FULL_SCREEN = ['fixed', 'h-[100vh]', 'w-full', 'top-0', 'left-0']

const MASK_CONFIG = {
  block: 'bg-black',
  none: '',
  visible: 'bg-[rgba(0,0,0,0.9)]',
}

interface Props {
  isLoading?: boolean
  backgroundMask?: keyof typeof MASK_CONFIG
}

export default function LoadingContainer({
  isLoading = true,
  backgroundMask = 'visible',
}: Props) {
  const count = useRef(0)
  const [outer, setOuter] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (isLoading) count.current++
    setOuter(document.getElementById('_outer'))
  }, [])

  if (!outer) return null

  return createPortal(
    <div>
      {isLoading && (
        <div className={clsx(FULL_SCREEN, 'z-20', MASK_CONFIG[backgroundMask])}>
          <div className={clsx(FULL_SCREEN)}></div>
          <div
            className={clsx(
              'w-[80px]',
              'absolute',
              'translate-x-[-50%]',
              'translate-y-[-50%]',
              'top-[50%]',
              'left-[50%]',
              'z-21',
            )}
          >
            <Loading />
          </div>
        </div>
      )}
    </div>,
    outer,
  )
}
