import {
  ComponentProps,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'

import Header, { type HeaderProps } from '@/components/Header'
import Button, { ButtonProps } from '@/components/Button'
import Loading from '../Loading'

interface Props extends ComponentProps<'div'> {
  header?: HeaderProps
  bottomButton?: ButtonProps
  loading?: boolean
}
export default function BaseLayout({
  children,
  header,
  bottomButton,
  loading = false,
  className,
  ...props
}: PropsWithChildren<Props>) {
  const contentRef = useRef<HTMLDivElement>(null)

  const [isSafari, setIsSafari] = useState(false)

  useEffect(() => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
  }, [])
  return (
    <main
      className={clsx(
        'relative',
        'flex flex-col inner font-Briller relative',
        isSafari ? 'height-full' : 'h-full',
      )}
      // style={{ clipPath: 'inset(0 0 0 0)' }}
    >
      {loading && (
        <div
          className={clsx(
            'absolute',
            'w-full',
            'h-[100vh]',
            'top-0',
            'left-0',
            'bg-black',
            'justify-center',
            'items-center',
            'flex',
            header && 'pt-[72px]',
          )}
        >
          <Loading />
        </div>
      )}
      {header && !loading && (
        <>
          <Header {...header} />
          <div className={clsx('h-[72px]', 'w-full')} />
        </>
      )}
      <div
        ref={contentRef}
        className={clsx(
          'flex-1',
          'h-full',
          'overflow-y-auto',
          'flex',
          'flex-col',
          className,
        )}
        {...props}
      >
        {children}
      </div>
      {bottomButton && (
        <>
          <div
            className={clsx('absolute', 'w-full', 'bottom-0', 'px-4', 'pb-4')}
          >
            <Button {...bottomButton} />
          </div>
          <div className="w-full h-[120px]" />
        </>
      )}
    </main>
  )
}
