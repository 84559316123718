/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    fontSize: {
      sm: '12px',
      base: '14px',
      lg: '16px',
      xl: '18px',
      '2xl': '20px',
      '3xl': '22px',
      '4xl': '24px',
    },
    colors: {
      black: '#101012',
      white: '#EBEBEB',
      gray300: '#C8C8C8',
      gray500: '#7A7A7A',
      gray700: '#383838',
      orange: '#EA6E15',
      orange2: '#D86200',
      blue: '#64C7FF',
      red: '#FF4F4F',
      pink: '#FFA29A',
      pink2: '#EB857C',
    },
    fontFamily: {
      Pretendard: ['var(--font-Pretendard)'],
      DespairTime: ['var(--font-DespairTime)'],
    },
    extend: {
      keyframes: {
        loading: {
          '0%, 100%': { left: '0%' },
          '50%': { left: '100%' },
        },
      },
      animation: {
        loading: 'loading 2500ms cubic-bezier(0.65, 0, 0.35, 1) infinite',
      },
    },
  },
  plugins: [],
}
